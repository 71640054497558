.inner-carousel {
  display: flex;
}
.carousel {
  overflow: hidden;
  cursor: grab;
  height: auto;
}
.item {
  min-width: 15rem;
  padding: 20px;
  position: relative;
}
.text-overlay {
  position: absolute;

  width: 100%;
  color: white;
  padding: 10px; /* Adjust padding as needed */
  box-sizing: border-box;
}
.image {
  width: 100%;
  height: 80%;
  border-radius: 1rem;
  pointer-events: none;
  margin: 5px;
}
.title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  text-align: center;
  padding: 10px; /* Adjust as needed */
  margin: 0; /* Remove any default margins */
}

[dir='rtl'] .inner-carousel {
  direction: rtl; /* Inverser la direction du carrousel */
}

[dir='rtl'] .text-overlay {
  left: auto; /* Réinitialiser left */
  right: 0; /* Aligner à droite */
  text-align: right; /* Aligner le texte à droite */
}

[dir='rtl'] .title {
  left: auto; /* Réinitialiser left */
  right: 0; /* Aligner à droite */
  text-align: right; /* Aligner le texte à droite */
}
