/* in chatbotComponents/Options.css */
.options-container {
  /* Center the buttons horizontally */
  margin-top: 10px;
}

.option-button {
  padding: 5px 10px;
  margin: 5px;
  border: 2px solid #18e68e;
  background-color: white;
  color: black;
  cursor: pointer;
  border-radius: 20px; /* Rounded corners */
  font-size: 12px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.option-button:hover {
  background-color: #18e68e;
  color: black;
}
