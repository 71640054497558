.links {
  color: #18e68e;
}
.btn-lg {
  color: black;
  border: none;
  background-color: #18e68e;
}
.btn-lg:hover {
  color: black;
  border: none;
  background-color: #18e68e;
}
.sign-in-text {
  font-weight: bolder;
  font-size: 24px;
  color: #18e68e;
}
.sign-in-description-text {
  font-weight: bolder;
  font-size: 24px;
  color: white;
}
.form-control:focus {
  border-color: transparent;
  box-shadow: transparent;
}
.signin-section {
  background: rgb(18, 26, 37);
  box-shadow: 10px 10px 50px #000000;
  border-radius: 25px;
}
.input-params {
  background: #3b404b;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  height: 45px;
  padding-left: 1.1rem;
  color: white;
}

.input-params:focus {
  background: #3b404b;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  height: 45px;
  padding-left: 1.1rem;
  background-color: none;
}

.footercx {
  background-color: rgb(18, 26, 37);
}

.input_textarea {
  background: #3b404b;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  height: 45px;
  padding-left: 1.1rem;
}
.order_online3 {
  padding: 10px 20px;
  background-color: #18e68e;
  border-radius: 15px;
  height: 50px;
  color: #121a25;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #18e68e;
  text-align: center;
}

.order_online3:hover {
  padding: 10px 20px;
  background-color: transparent;
  border-radius: 15px;
  height: 50px;
  color: #18e68e;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #18e68e;
  text-align: center;
}

.register_ {
  padding: 8px 30px;

  background-color: transparent;
  color: #18e68e;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #18e68e;
}
.slider_section {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 60px 0 130px 0;
}

.slider_section .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.qrcode {
  margin-top: 20%;
}
@media (max-width: 768px) {
  .qrcode {
    margin-top: 0%;
  }
}
.slider_section #customCarousel1 {
  width: 100%;
  position: unset;
}

.slider_section .detail-box {
  position: absolute;
  width: 635.08px;
  height: 236px;
  left: 402px;
  top: 307px;
}

.slider_section .detail-box h1 {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.slider_section .detail-box p {
  font-size: 14px;
}

.slider_section .detail-box a {
  display: inline-block;
  padding: 10px 45px;
  background-color: #18e68e;
  color: #ffffff;
  border-radius: 45px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  margin-top: 10px;
}

.slider_section .detail-box a:hover {
  background-color: #18e68e;
}

.slider_section .img-box img {
  width: 100%;
}

.slider_section .carousel-indicators {
  position: unset;
  margin: 0;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider_section .carousel-indicators li {
  background-color: #ffffff;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  opacity: 1;
}

.slider_section .carousel-indicators li.active {
  width: 20px;
  height: 20px;
  background-color: #18e68e;
}

.card_login {
  background-color: #121a25;
  box-shadow: 10px 10px 50px #000000;
  border-radius: 25px;
}
.card_register_mobile {
  margin-top: -40px;
  background-color: #121a25;
  box-shadow: 10px 10px 50px #000000;
  border-radius: 25px;
}
.card_login {
  margin-top: 20px;
}
.sub_title {
  position: relative;
  height: 29px;
  left: 3.86%;
  right: 79.57%;
  top: calc(50% - 29px / 2 - 213.5px);

  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: 0.2px;

  color: #18e68e;
}
.sub_titleAb {
  position: relative;
  height: 60px;
  display: flex;
  justify-content: center;

  font-style: normal;
  font-weight: bolder;
  font-size: 36px;
  line-height: 29px;
  /* identical to box height */
  letter-spacing: 0.9px;
  color: #18e68e;
}
.sub_titleP {
  position: relative;
  height: 25px;
  display: flex;
  justify-content: center;

  font-style: normal;
  font-weight: bolder;
  font-size: 18px;
  line-height: 29px;
  /* identical to box height */
  letter-spacing: 0.9px;
  color: #ffffff;
}

.text_about {
  position: absolute;
  /*left: 70px;*/
  top: calc(50% - 29px / 2 - 170.5px);

  font-style: normal;
  font-size: 14px;
  line-height: 30px;
  /* or 188% */

  text-align: justify;
  letter-spacing: 0.17px;

  color: #d3d3d3;
}
.text-login1 {
  color: #ada8b8;
  text-align: right;
}

.text-login {
  color: #ada8b8;
  text-align: justify;
}
.input_login {
  background: #3b404b;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  height: 45px;
  padding-left: 1.1rem;
}

.c-recaptcha {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 767px) {
  .c-recaptcha {
    transform: scale(0.7);
    -webkit-transform: scale(0.8);
    transform-origin: 0 0;
    margin-left: 15%;
    -webkit-transform-origin: 0 0;
  }
}
.btnlogin {
  background: #18e68e;
  border: 1px solid #18e68e;
  border-radius: 45px;
  padding-top: 0.395rem;
}
.textsignin {
  color: #121a25;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 4px);
  display: block;
  border: 2px solid #adb5bd;
  border-radius: 50%;
  color: #adb5bd;
}

.register-div {
  display: flex;
  justify-content: center;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 4px);
  display: block;
  border: 2px solid #adb5bd;
  border-radius: 50%;
  color: #adb5bd;
}
.rc-anchor-dark {
  background-color: red !important;
  color: #ffffff !important;
}
