@charset "UTF-8";
* {
  
  font-family:  'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


::-webkit-scrollbar {
  width: 10px; 
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: #18e68e; 
  border-radius: 6px; 
}

::-webkit-scrollbar-thumb:hover {
  background: #0ab76c; 
}

