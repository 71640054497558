.header-container {
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative; /* Ensure relative positioning for absolute positioning of .header-image */
  background-image: url('./../images/header/header.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.header-image {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%; /* Change to percentage to fill the entire height of .header-container */
  z-index: -1;
  background-image: url('./../images/header/header.png');
}

.chatbot-toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #18e68e;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.chatbot-toggle-button img {
  width: 30px;
  height: 30px;
}

.chatbot-container {
  position: fixed;
  bottom: 90px;
  right: 20px;
  max-width: 90%;

  height: 500px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
}

/* Chatbot User Message */
.chatbot-container .react-chatbot-kit-chat-bot-message-container,
.chatbot-container .react-chatbot-kit-chat-user-message-container {
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 5px 0;
}

/* Chatbot Bot Message */
.chatbot-container .react-chatbot-kit-chat-bot-message {
  background-color: #18e68e;
  color: black;
  border-radius: 10px;
}
.react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid #18e68e !important;
}
.react-chatbot-kit-chat-btn-send {
  background-color: #18e68e !important;
  width: 100px;
  border: none;
  color: #fff;
  border-bottom-right-radius: 5px;
}
.react-chatbot-kit-chat-input {
  background-color: white !important;
  color: black !important;
}
[dir='rtl'] .header-container {
  text-align: right; /* Aligner le texte à droite */
}

[dir='rtl'] .chatbot-toggle-button {
  left: 20px; /* Déplacer le bouton du chatbot à gauche */
  right: auto;
}

[dir='rtl'] .chatbot-container {
  left: 20px; /* Déplacer le conteneur du chatbot à gauche */
  right: auto;
}
