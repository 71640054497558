.nav-link {
  font-size: larger;
  color: white;
}
.nav-link:hover {
  color: #18e68e;
}
.active_link {
  color: #18e68e;
}
.button {
  display: inline-block;
  padding: 8px 30px;
  background-color: transparent;
  color: #18e68e;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #18e68e;
}
.button:hover {
  background-color: transparent;
  border: 1px solid #18e68e;
  color: #18e68e;
}
@media screen and (max-width: 1200px) {
  .nav-link {
    font-size: large;
    color: white;
  }
  .buttons-div {
    justify-content: center;
    align-items: center;
  }
}

[dir='rtl'] .nav-link {
  text-align: right; /* Aligner le texte à droite */
}

[dir='rtl'] .buttons-div {
  direction: rtl; /* Inverser la direction des boutons */
}
